import axios from 'axios';
import { ref } from 'vue';
import global from './global';

const lineItems = ref({} as any);

const getReport = (month: string) => {
  lineItems.value = {};
  const months = [
    'January',
    'Febuary',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const monthNum = months.indexOf(month);
  const strDate = new Date();
  let year = strDate.getFullYear();
  if (strDate.getMonth() < monthNum) year -= 1;
  strDate.setFullYear(year, monthNum, 1);
  strDate.setHours(0, 0, 0, 0);
  const endDate = new Date(strDate);
  endDate.setMonth(monthNum + 1);
  endDate.setDate(0);
  endDate.setHours(23, 59, 59, 999);

  axios
    .get(global.serverString + 'monthlyreport', {
      params: {
        strDate: strDate.getTime(),
        endDate: endDate.getTime(),
      },
    })
    .then((response) => {
      if (response.statusText != 'OK') throw 'status not OK';

      response.data.forEach((v: any) => {
        if (!lineItems.value[v.name]) {
          lineItems.value[v.name] = [];
        }
        lineItems.value[v.name].push(v);
      });
    })
    .catch((err) => {
      if (err.response.data.status == 'Auth Failed') global.logoff();
      console.log(err);
    });
};

export default {
  lineItems,
  getReport,
};
