<template>
  <div class="main-panel">
    <h1>Monthly Report</h1>
    <select v-model="selectedMonth" :class="{ 'not-placeholder': selectedMonth != null }">
      <option :value="null" disabled selected>month</option>
      <option v-for="month in months" :key="month" :value="month">
        {{ month }}
      </option>
    </select>

    <div class="company" v-for="(company, name) in lineItems" :key="name">
      <div class="header">
        <h2>{{ name }}</h2>
      </div>
      <div class="ticket" v-for="ticket in company" :key="ticket.ticketid">
        <div>#{{ ticket.ticketnumber }}</div>
        <div class="desc">{{ ticket.description }}</div>
        <div class="date">{{ formatDate(ticket.datecreated) }} - {{ formatDate(ticket.dateclosed) }}</div>
        <div class="time">{{ formatHours(ticket.tickettime) }} Hours</div>
      </div>
      <div class="total">
        <span>{{ getTotal(company) }} Total</span>
      </div>
    </div>
  </div>
</template>

<script>
import monthlyReport from '../composables/monthlyReport';

export default {
  data() {
    return {
      selectedMonth: null,
    };
  },
  setup() {
    monthlyReport.lineItems.value = {};
    return {
      lineItems: monthlyReport.lineItems,
      getReport: monthlyReport.getReport,
    };
  },
  watch: {
    selectedMonth() {
      this.getReport(this.selectedMonth);
    },
  },
  methods: {
    formatDate(dateNumber) {
      if (dateNumber == null) {
        return '';
      }
      if (dateNumber.toString().length != 13) {
        return dateNumber;
      }
      dateNumber = parseInt(dateNumber);
      const date = new Date(dateNumber);
      return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    },
    formatHours(millis) {
      return (millis / 3.6e6).toFixed(2);
    },
    getTotal(company) {
      let total = 0;
      console.log(company);
      company.forEach((v) => {
        total += v.tickettime;
      });
      return this.formatHours(total);
    },
  },
  computed: {
    months() {
      const d = new Date();
      const currentMonth = d.getMonth();
      const currentMonths = [];
      const monthNames = [
        'January',
        'Febuary',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];

      for (let i = 0; i < 10; i++) {
        let tmpMonthNum = currentMonth - i;
        if (tmpMonthNum < 0) tmpMonthNum += 12;
        currentMonths.push(monthNames[tmpMonthNum]);
      }

      return currentMonths;
    },
  },
};
</script>

<style lang="scss" scoped>
.company {
  max-width: 800px;

  .header {
    border-bottom: 3px solid #dfdfdf;
    h2 {
      margin-bottom: 5px;
      font-size: 1.8em;
      font-weight: 400;
    }
  }

  .ticket {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    div {
      margin: 12px;
      margin-bottom: 0px;
      font-size: 1.2em;
      flex: 1;
    }
    .date,
    .desc {
      flex: 2;
    }
    .time {
      text-align: right;
    }
  }

  .total {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    span {
      margin-top: 10px;
      font-size: 1.2em;
      padding: 12px;
      padding-top: 6px;
      border-top: 3px solid #dfdfdf;
    }
  }
}
</style>
